import * as apiService from "../../Utility/api.service";
import { BASE_URL } from "../../Utility/constants";

export const getTokenForUser = async (payload) =>
  apiService.postDataWithOutAuth(`${BASE_URL}/authorization/token`, payload);

export const getRespondentDetails = async (Username) =>
  apiService.getData(`${BASE_URL}/taker/respondentByUsername/${Username}`);

export const getActiveSurveys = async (RespondentId) =>
  apiService.getData(
    `${BASE_URL}/taker/activeSurveysByRespondentId/${RespondentId}`
  );

export const getSectionsByUser = async (payload) =>
  apiService.getData(
    `${BASE_URL}/taker/sectionFilings/${payload.surveyFilingId}/${payload.surveyRespondentId}`
  );

export const getPages = async (SectionId) =>
  apiService.getData(`${BASE_URL}/builder/pages/${SectionId}`);

export const getCountryCodes = async () =>
  apiService.getData(`${BASE_URL}/builder/states`);

export const getPageData = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/displayPage`, payload);

export const displaySection = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/displaySection`, payload);

export const getSurveyLookupFiling = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/lookupFiling`, payload);

export const getPageFilings = async (SurveyFilingId) =>
  apiService.getData(
    `${BASE_URL}/taker/pageFilingsBySurveyFilingId/${SurveyFilingId}`
  );

export const getAllPages = async (SurveyId) =>
  apiService.getData(`${BASE_URL}/builder/allSurveyPages/${SurveyId}`);

export const saveAnswers = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/save`, payload);

export const getPreviousPage = async (payload) =>
  apiService.getData(
    `${BASE_URL}/taker/previousPageFiling/${payload.pageFilingId}/${payload.surveyRespondentId}`
  );

export const getNextPage = async (payload) =>
  apiService.getData(
    `${BASE_URL}/taker/nextPageFiling/${payload.pageFilingId}/${payload.surveyRespondentId}`
  );

export const validatePage = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/validate`, payload);

export const errorCorrectionQes = async (errorId) =>
  apiService.getData(`${BASE_URL}/taker/displayErrorCorrectionPage/${errorId}`);

export const saveCorrectionQues = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/fixError`, payload);

export const getPageDataOfLastYear = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/populatePageFilingHistory`, payload);

export const getSectionErrors = async (payload) =>
  apiService.getData(
    `${BASE_URL}/taker/errors/${payload.surveyFilingId}/${payload.sectionId}`
  );

export const getAllErrors = async (payload) =>
  apiService.getData(
    `${BASE_URL}/taker/allErrors/${payload.surveyFilingId}/${payload.surveyRespondentId}`
  );

export const getHistoricalFiling = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/historicalFiling`, payload);

export const getSurveyFilingDto = async (surveyFilingId) =>
  apiService.getData(`${BASE_URL}/taker/surveyFiling/${surveyFilingId}`);

export const getPrintSurveyMenu = async (payload) =>
  apiService.getData(
    `${BASE_URL}/taker/printmenusurveys/${payload?.surveyFilingId}/${payload?.surveyRespondentId}`
  );

export const createSurveyHelper = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/surveyHelper`, payload);

export const saveSurveySectionAssignments = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/sectionAssignments`, payload);

export const getSurveyRespondents = async (surveyOrganizationId) =>
  apiService.getData(
    `${BASE_URL}/panel/surveyRespondents/${surveyOrganizationId}`
  );

export const deleteSurveyRespondents = async (payload) =>
  apiService.deleteMethod(`${BASE_URL}/panel/surveyHelper`, payload);
export const updateSurveyRespondents = async (payload) =>
  apiService.putData(`${BASE_URL}/panel/surveyRespondent`, payload);

export const getSectionAssignments = async (payload) =>
  apiService.getData(
    `${BASE_URL}/panel/sectionAssignments/${payload.surveyFilingId}/${payload.surveyRespondentId}`
  );

export const getSurveyProgressReport = async (surveyFilingId) =>
  apiService.getData(
    `${BASE_URL}/taker/surveyProgressReport/${surveyFilingId}`
  );

export const getSupplementalFiling = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/supplementalFiling`, payload);

export const getGlossary = async (payload) => {
  if (payload?.surveyId && !payload?.sectionId)
    return apiService.getData(
      `${BASE_URL}/taker/surveyGlossary/${payload?.surveyId}`
    );
  if (payload?.surveyId && payload?.sectionId)
    return apiService.getData(
      `${BASE_URL}/taker/surveyGlossary/${payload?.surveyId}/${payload?.sectionId}`
    );
};

export const getSurveyDto = async (surveyId) =>
  apiService.getData(`${BASE_URL}/builder/survey/${surveyId}`);

export const surveyLogo = async (surveyId) =>
  apiService.getData(`${BASE_URL}/builder/surveyLogo/${surveyId}`);

export const submitSurvey = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/submitSurvey`, payload);

export const getLoginData = async (payload) =>
  apiService.getData(`${BASE_URL}/taker/ssoAnnualTakerLogin`);

export const getBuilderLoginData = async (surveyFilingId) =>
  apiService.getData(
    `${BASE_URL}/taker/builderAnnualTakerLogin/${surveyFilingId}`
  );

export const getIsAPILoggedIn = async (payload) =>
  apiService.getData(`${BASE_URL}/authorization/isLoggedIn`);

export const saveComment = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/comment`, payload);

export const commentsReport = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/commentsReport`, payload);

export const showPageFilingHistoryLink = async (pageId, surveyFilingId) =>
  apiService.getData(
    `${BASE_URL}/taker/showPageFilingHistoryLink/${pageId}/${surveyFilingId}`
  );

export const getSurveyHelperUploadKeyFile = async (surveyId, surveyFilingId) =>
  apiService.getData(
    `${BASE_URL}/survey-processing/surveyHelperUploadKeyFile/${surveyId}/${surveyFilingId}`
  );

export const validateSurveyHelpersUploadFile = async (
  file,
  surveyId,
  surveyFilingId
) =>
  apiService.postFormData(
    `${BASE_URL}/survey-processing/validateSurveyHelpersUploadFile/${surveyId}/${surveyFilingId}`,
    file
  );
export const uploadSurveyHelpers = async (
  file,
  surveyId,
  surveyOrgId,
  surveyFilingId,
  respondentId
) =>
  apiService.postFormData(
    `${BASE_URL}/survey-processing/uploadSurveyHelpers/${surveyId}/${surveyOrgId}/${surveyFilingId}/${respondentId}`,
    file
  );

export const findBatchById = async (batchId) =>
  apiService.getData(`${BASE_URL}/survey-processing/findBatchById/${batchId}`);

export const batchExceptions = async (batchId) =>
  apiService.getData(
    `${BASE_URL}/survey-processing/batchExceptions/${batchId}`
  );
